import { edit_lead, add_tag, edit_notes, get_leads, reset_tag, get_sellers, merge_leads, search_lead } from "../../../services/api/Leads";
import { assign_seller } from "../../../services/api/History";
import { alertMessage } from "../../../helpers/messagesAlerts";

const handleSelections = (lead, selectionA, selectionB, setSelectionA, setSelectionB) => {
    
    if (selectionA?._id == lead?._id) {
        setSelectionA(false);
        setSelectionB(false);
    }
    else if (selectionB?._id == lead?._id) setSelectionB(false);
    else if (!selectionA) setSelectionA(lead);
    else if (!selectionB) setSelectionB(lead);
    else if ((selectionA && selectionB)) {
        setSelectionA(lead);
        setSelectionB(false);
    }
}

const handleCloseSlider = (setSelectedLead, setSlider) => {
    setSelectedLead(null);
    setSlider('');
};

const handleFilters = (category, value, setFilters)  => {

    console.log("filters!!", category, value);
    
    setFilters(prevFilters => {
        const categoryFilters = prevFilters[category];
        const updatedCategory = categoryFilters.includes(value)
          ? categoryFilters.filter(item => item !== value)
          : [...categoryFilters, value];
  
        return {
          ...prevFilters,
          [category]: updatedCategory
        };
      });
  };

const getSellers = (setSellers) =>{
    get_sellers()
        .then(data => {
            setSellers(data)
        })
        .catch(err => {
            console.log(err)
        })
}

const getLeads = (page, pageSize, leads, setLeads, setTotalLeads, setPageNo, setSources, slider, search) => {

    let get_lead_promise = () => get_leads(1, page*pageSize)
    let search_lead_promise = () => search_lead(search)

    
    let get_or_search = search ? search_lead_promise: get_lead_promise;
    console.error('SEARCCH', search)
    console.error('SEARCCH', get_or_search)

    if (slider == 'edit') {
        get_or_search()
        .then(data => {
            console.error('SEARCCH DAT', data)
            
            if (search) {
                setLeads(data)
            }
            else {
                setLeads(data?.leads);
            }

            setTotalLeads(data?.count)

            setPageNo(page+1)
        })
        .catch(err => {
            console.log(err)
        })
    }
    else {
        get_leads(page, pageSize)
        .then(data => {
            if (page == 1) {
                setLeads(data?.leads);
            }
            else {
                setLeads([...leads, ...data?.leads]);
            }
            
            setTotalLeads(data?.count)

            const findSources = data?.leads?.reduce((acc, lead) => {
                if (lead.source && !acc.includes(lead.source)) {
                    acc.push(lead.source);
                }
                return acc;
            }, []);

            setSources(findSources);

            setPageNo(page+1)
        })
        .catch(err => {
            console.log(err)
        })
    }
}

const handleSave = async (selectedLead, setSelectedLead, alerts, setAlerts, setSlider, user, pageNo, t, slider, pageSize, setPageNo, leads, setLeads, setTotalLeads, setSources, setLoading, userChanged, search) => {

    console.error('lets edit', selectedLead)
    console.error('lets edit', selectedLead?.customTag)

    setLoading(true);


    if (selectedLead?.mergeLead && selectedLead?.mergeLead !== '') {

        let res;

        res = await merge_leads(selectedLead).catch(err => {console.log(err)})

        console.log('RESSSSSSSSSSSSSSSSSSSSSSSS', res);
                
        if (res?.data?.code !== 'T2017') {
            setLoading(false);
            alertMessage(alerts, setAlerts, res?.data?.msg, 'Error');
            return;
        }
    }

    if (selectedLead?.edit) {

        await edit_lead(selectedLead).catch(err => {console.log(err)})

        setSelectedLead({ ...selectedLead, edit: false })
    }

    if (selectedLead?.customTag) {

        let res;

        res = await add_tag(selectedLead).catch(err => {console.log(err)})

        console.log('RESSSSSSSSSSSSSSSSSSSSSSSS', res);
                
        if (res?.data?.code !== 'T2004') alertMessage(alerts, setAlerts, (t('There was an error adding a new note')), 'Error');
    }

    if (selectedLead?.customTag == '') {

        let res; 

        res = await reset_tag(selectedLead).catch(err => {console.log(err)})

        if (res?.data?.code !== 'T2004') alertMessage(alerts, setAlerts, (t('There was an error resetting tags')), 'Error');
    }

    console.log('initialUserRef?.current?.id !== user?.id', userChanged);
    
    if(userChanged) {

        // console.log('USER', user);
        
        await assign_seller(selectedLead, user).catch(err => {console.log(err)})

       // alertMessage(alerts, setAlerts,  (t('Lead edited successfully')), 'Success');
                // if (res.data.code === 'T2011') alertMessage(alerts, setAlerts,  (t('Lead edited successfully')), 'Success');
                // else alertMessage(alerts, setAlerts, (t('There was an error assigning seller')), 'Error')
    }

    // if (note) {
    let notes = await edit_notes(selectedLead).catch(err => {console.log(err)})

    console.log('PPP', notes);
            
    if (notes.data.code === 'T2015') alertMessage(alerts, setAlerts, (t('Lead edited successfully')), 'Success');
    else alertMessage(alerts, setAlerts, (t('There was an error editing notes')), 'Error');

    setLoading(false);

    getLeads(pageNo-1, pageSize, leads, setLeads, setTotalLeads, setPageNo, setSources, slider, search);
    handleCloseSlider(setSelectedLead, setSlider);
};

const getUserInfoOfAssignedSeller = (lead) => {

    if (!lead?.histories || !lead?.histories?.length) return '';

    let lastAssignedSellerIndex = lead.histories.length - 1;

    lead.histories[lastAssignedSellerIndex].seller.id = lead?.histories[lastAssignedSellerIndex]?.seller.sellerId;

    return lead?.histories[lastAssignedSellerIndex]?.seller;
}

const displayOpenTagAlongsideUnregistered = (lead) => {

    let tags = lead?.tags?.filter(tag => tag.type !== 'source' && tag?.status && tag?.name !== 'Open');

    // if (tags?.length && tags?.length == 1) {
    //     if (tags[0].name == 'Unregistered') tags.push({name: 'Open'})
    // }

    return tags;
}

const tagsList = (tags) => {

    return tags.map((tag, index) => {

        if (tag.type === 'source') {
            if (tag.name.includes('form')) {
                return { ...tag, action: 'Unregistered' };
            }
            else {
                return { ...tag, action: 'Registered' };
            }
        }

        if (tag.name === 'Unregistered' && !tag.status) {
            return { ...tag, action: 'Registered', createdAt: tag.updatedAt };
        }
        else if (tag.name === 'Unregistered' && tag.status) {
            return null;
        }

        return { ...tag, action: tag.name };

    }).filter(tag => tag);
};


const choice_no = () => {

}

const choice_yes = () => {
    
}


export {
    handleSelections, 
    choice_no, 
    choice_yes, 
    handleSave, 
    handleCloseSlider,
    handleFilters,
    getLeads,
    getSellers,
    getUserInfoOfAssignedSeller,
    displayOpenTagAlongsideUnregistered,
    tagsList
}